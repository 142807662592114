import { addHours } from "../time-utils";
import { isDev, isStage } from "./constants";

const isSandbox = isDev || isStage;

export const SAMPLE_PLAN = {
  id: "sample",
  title: "Sample",
  name: "Sample", // Old field
  credits: 10,
  price: 0,
  sku: "sample",
  type: "onetime",
  stripeId: "", // isSandbox ? "price_1O59QZFonTTiBhVFyeDD1EgI" : "",

  background: "linear-gradient(to right, #cdffc8, #94b9ff)",
  bottomTag: (
    <>
      ONE TIME
      <br />
      <strong>CLAIM NOW</strong>
    </>
  ),
  description:
    "Sample Package is perfect for those who want to get a flavor of the AdmitYogi tools. With this package, you can understand the competitiveness of the admissions landscape or gauge how close your essay is to a submission-ready version.",
};

export const GO_PLAN = {
  id: "go",
  title: "Go",
  name: "Go", // Old field
  credits: 30,
  price: 49,
  sku: "go",
  type: "onetime",
  stripeId: isSandbox ? "price_1O59U9FonTTiBhVFGLOBYThf" : "",

  background: "linear-gradient(to right, #0cc0df, #FFDE59)",
  description:
    "Go Package is tailored for students applying to a single college, ensuring an insightful understanding of the admissions process and refining their core essay.",
};

export const PRO_PLAN = {
  id: "pro",
  title: "Pro",
  name: "Pro", // Old field
  credits: 150,
  price: 165,
  sku: "pro",
  type: "onetime",
  stripeId: isSandbox ? "price_1O59UwFonTTiBhVFu7DqFq36" : "",
  topTag: "MOST POPULAR",

  background: "linear-gradient(to right, #FFDE59, #FF914D)",
  description:
    "Pro Package is designed for those applying to 3-5 colleges, offering comprehensive student profile insights and extensive essay preparation.",
};

// export const ULTRA_PLAN = {
//   id: "ultra",
//   title: "Ultra",
//   credits: 300,
//   price: 225,
//   sku: "ultra",
//   name: "Ultra", // Old field
//   type: "onetime",
//   stripeId: isSandbox ? "price_1O5A9SFonTTiBhVF2wjFlyLJ" : "",
//
//   background: "linear-gradient(to right, #FF914E, #FF37B2)",
//   topTag: "MOST POPULAR",
//   selectedColor: "#fff",
//   description:
//     "Ultra Package is best suited for applicants targeting 6-10 colleges, ensuring a deep dive into student profiles and abundant personal statement and supplemental essay revisions.",
// };

export const MAX_PLAN = {
  id: "max",
  title: "Max",
  credits: 500,
  price: 295,
  sku: "max",
  name: "Max", // Old field
  type: "onetime",
  stripeId: isSandbox ? "price_1O5ABVFonTTiBhVFUQRM9c0p" : "",

  background: "linear-gradient(to right, #EF38AA, #9659DE)",
  selectedColor: "#fff",
  description:
    "Max Package is designed for those aiming for 10+ colleges or seeking the utmost in-depth understanding of student profiles and rigorous revisions for personal statements and school-specific essays.",
};

export const PROFILE_50 = {
  id: "profiles_50",
  sku: "profiles_50",
  title: "Profiles 50",
  credits: 50,
  price: 50,
  initialPrice: 50,
  isMostPopular: false,
};

export const PROFILE_150 = {
  id: "profiles_150",
  sku: "profiles_150",
  title: "Profiles 150",
  credits: 150,
  price: 135,
  initialPrice: 150,
  isMostPopular: true,
};

export const PROFILE_500 = {
  id: "profiles_500",
  sku: "profiles_500",
  title: "Profiles 500",
  credits: 500,
  price: 325,
  initialPrice: 500,
  isMostPopular: false,
};

export const ESSAY_50 = {
  id: "essays_50",
  sku: "essays_50",
  title: "Essays 50",
  credits: 50,
  price: 50,
  initialPrice: 50,
  isMostPopular: false,
};

export const ESSAY_PASS_1_MO = {
  id: "essays_pass_1",
  sku: "essays_pass_1",
  title: "1 month Essays Pass",
  period: 1,
  credits: 0,
  initialPrice: 100,
  price: 75,
  isMostPopular: true,
};

export const ESSAY_PASS_3_MO = {
  id: "essays_pass_3",
  sku: "essays_pass_3",
  title: "3 months Essays Pass",
  period: 3,
  credits: 0,
  initialPrice: 300,
  price: 150,
  isMostPopular: false,
};

export const ENTERPRISE_GO = {
  id: "enterprise_go",
  sku: "enterprise_go",
  stripeId: "",
  title: "Enterprise Go",
  price: 499,
  enterprise_unlocks: 50,
  enterprise_revisions: 50,
};

export const ENTERPRISE_PRO = {
  id: "enterprise_pro",
  sku: "enterprise_pro",
  stripeId: "",
  title: "Enterprise Pro",
  price: 1099,
  enterprise_unlocks: 150,
  enterprise_revisions: 150,
};

export const ENTERPRISE_GOLD = {
  id: "enterprise_gold",
  sku: "enterprise_gold",
  stripeId: "",
  title: "Enterprise Gold",
  price: 2499,
  enterprise_unlocks: 300,
  // enterprise_revisions: 150,
  period: 100 * 12, // 100 years
};

export const ENTERPRISE_PLANS = [ENTERPRISE_GO, ENTERPRISE_PRO, ENTERPRISE_GOLD];

// DEPRECATED
export const PLANS = [SAMPLE_PLAN, GO_PLAN, PRO_PLAN, MAX_PLAN];
// NEW
export const PROFILE_PLANS = [PROFILE_50, PROFILE_150, PROFILE_500];
export const ESSAY_PLANS = [ESSAY_50, ESSAY_PASS_1_MO, ESSAY_PASS_3_MO];

export const CREDITS_PER_PROFILE = 10;
export const CREDITS_PER_ESSAY = 5;
export const CREDITS_PER_ESSAY_PASS = 30;

// Whenever you change the lightning sale percentage, you need to change the stripe prices
export const LIGHTNING_SALE = {
  percentage: 0.5,
  startDate: new Date("2023-06-29T21:00:00.000Z"),
  getEndDate: function () {
    return addHours(this.startDate, 10);
  },
  isActive: function () {
    return new Date() > this.startDate && new Date() < this.getEndDate();
  },
};

if (LIGHTNING_SALE.isActive()) {
  for (const plan of PLANS) {
    if (plan.hasAppliedLightningSale) {
      continue;
    }

    plan.hasAppliedLightningSale = true;
    plan.price = Math.round(plan.price * (1 - LIGHTNING_SALE.percentage) * 100) / 100;
  }
}

// TODO: DEPRECATE THESE
export function getProductBySku(sku) {
  return PLANS.find((product) => product.sku === sku);
}
export function getProductByName(name) {
  return PLANS.find((product) => product.name === name);
}

// TODO: DEPRECATE THESE
// Only used in deprecated pricing plan
export function getPriceForProduct(id) {
  return PLANS.find((product) => product.id === id).price;
}

// export function getProductByStripeId(stripeId) {
//   for (const product of productList) {
//     for (const price of product.prices) {
//       if (price.stripeId === stripeId) return product;
//     }
//   }
// }
//
// export function getPriceByStripeId(stripeId) {
//   for (const product of productList) {
//     for (const price of product.prices) {
//       if (price.stripeId === stripeId) return price;
//     }
//   }
// }
