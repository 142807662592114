import { lazy, Suspense } from "react";

const GetStartedButton = lazy(() => import("./GetStartedButton"));

export default function GetStartedButtonLoader({ isOpen, inverted }) {
  // Don't just check for !isOpen because it'll be undefined on desktop
  if (isOpen === false) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <GetStartedButton inverted={inverted} />
    </Suspense>
  );
}
