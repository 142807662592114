const vowels = ["a", "e", "i", "o", "u"];
export const COLORS = ["red", "green", "pink", "blue", "yellow", "orange"];

export function isValidReferralCode(str) {
  return /^[a-zA-Z0-9]{1,20}$/.test(str);
}

export function getArrayDiffs(arr1, arr2) {
  const diffs = [];
  for (const str of new Set([...arr1, ...arr2])) {
    const count1 = arr1.filter((s) => s === str).length;
    const count2 = arr2.filter((s) => s === str).length;
    if (count1 > count2) {
      diffs.push({ value: str, removed: true });
    } else if (count1 < count2) {
      diffs.push({ value: str, added: true });
    }
  }
  return diffs;
}

export function capitalize(str) {
  if (!str) {
    return str;
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

export function combineDiffs(diffs) {
  for (let i = 0; i < diffs.length; i++) {
    if (
      diffs[i].removed &&
      i + 1 < diffs.length &&
      diffs[i + 1].added &&
      i + 2 < diffs.length &&
      diffs[i + 2].value === " " &&
      i + 3 < diffs.length &&
      diffs[i + 3].removed &&
      i + 4 < diffs.length
    ) {
      diffs[i].value += " " + diffs[i + 3].value;
      diffs[i + 1].value += " " + diffs[i + 4].value;
      // Remove space
      diffs.splice(i + 2, 3);
      i--;
    }
  }
}

export function isValidPhoneNumber(str) {
  return /^(\+\d{1,2}\s)?\(?(\d{3})\)?[\s.-]{0,1}(\d{3})[\s.-]{0,1}(\d{4})$/.test(str);
}

export function hasTenDigits(str) {
  return str.replace(/\D/g, "").length >= 10;
}

export function isValidEmail(str) {
  return /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
    str,
  );
}

export function validatePayoutAccount(payoutType, payoutAccount) {
  if (payoutType === "venmo") {
    if (!isValidPhoneNumber(payoutAccount)) {
      return "Your phone number should be in the format ###-###-#### (only US numbers are accepted).";
    }
  } else if (payoutType === "paypal") {
    if (!isValidEmail(payoutAccount)) {
      return "Enter a valid email address.";
    }
  }
}

export function getFilenameFromPath(path) {
  return path.substring(path.lastIndexOf("/") + 1);
}

export function btoyn(b) {
  return !!b ? "Yes" : "No";
}

export function countWords(s) {
  s = s.replace(/(^\s*)|(\s*$)/gi, ""); //exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi, " "); //2 or more space to 1
  s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
  return s.split(" ").filter(function (str) {
    return str !== "";
  }).length;
}

export function getLocation(origin_country, origin_state) {
  var builder = "";
  if (origin_country === "United States") {
    origin_country = "USA";
  } else if (origin_country === "Korea, Republic of") {
    origin_country = "South Korea";
  }
  if (origin_state === "District of Columbia") {
    origin_state = "DC";
  }
  if (origin_state && origin_state !== "") {
    builder = " student from " + origin_state;
  } else if (origin_country) {
    builder = " student from " + origin_country;
  }
  return builder;
}

export function toDate(epoch) {
  const d = new Date(0);
  d.setUTCSeconds(epoch);
  return d;
}

export function formatDate(date) {
  return date.toLocaleString("en-US", {
    timeZone: "CST",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    year: "numeric",
    hour12: false, // Use 24-hour format
  });
}

export function getRandomColor() {
  return COLORS[Math.floor(Math.random() * COLORS.length)];
}

export function formatDollars(num, defaultValue, strip00) {
  if (!num && num !== 0) {
    return defaultValue;
  }
  let val = "$" + (Math.floor(num * 100) / 100).toFixed(2);
  if (strip00 && val.endsWith(".00")) {
    return val.substring(0, val.length - 3);
  }
  return val;
}

export function levDist(s, t) {
  var d = []; //2d matrix

  // Step 1
  var n = s.length;
  var m = t.length;

  if (n == 0) return m;
  if (m == 0) return n;

  //Create an array of arrays in javascript (a descending loop is quicker)
  for (var i = n; i >= 0; i--) d[i] = [];

  // Step 2
  for (var i = n; i >= 0; i--) d[i][0] = i;
  for (var j = m; j >= 0; j--) d[0][j] = j;

  // Step 3
  for (var i = 1; i <= n; i++) {
    var s_i = s.charAt(i - 1);

    // Step 4
    for (var j = 1; j <= m; j++) {
      //Check the jagged ld total so far
      if (i == j && d[i][j] > 4) return n;

      var t_j = t.charAt(j - 1);
      var cost = s_i == t_j ? 0 : 1; // Step 5

      //Calculate the minimum
      var mi = d[i - 1][j] + 1;
      var b = d[i][j - 1] + 1;
      var c = d[i - 1][j - 1] + cost;

      if (b < mi) mi = b;
      if (c < mi) mi = c;

      d[i][j] = mi; // Step 6

      //Damerau transposition
      if (i > 1 && j > 1 && s_i == t.charAt(j - 2) && s.charAt(i - 2) == t_j) {
        d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
      }
    }
  }

  // Step 7
  return d[n][m];
}

export function aOrAn(next) {
  const nextChar = next.toLowerCase()[0];
  if (vowels.includes(nextChar)) return "an";
  else return "a";
}

export function makePlural(word, suffix, length) {
  if (length === 1) return word;
  else return word + suffix;
}

export function makePluralRegex(input, length) {
  const pluralRegex = /\(([^()]*)\/([^()]*)\)/;
  if (pluralRegex.test(input)) {
    const result = pluralRegex.exec(input);
    const replaceWith = length === 1 ? result[1] : result[2];
    return input.replace(pluralRegex, replaceWith);
  } else {
    return input;
  }
}

export function joinWithCommaAnd(input, and = "and") {
  var copy = [...input];
  if (input.length == 0) return "";
  if (input.length == 1) return input[0];
  if (input.length == 2) return input[0] + " " + and + " " + input[1];
  const last = copy.pop();
  return copy.join(", ") + ", " + and + " " + last;
}

export function isNumeric(str) {
  if (typeof str == "number") return true;
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function hashCode(string) {
  var hash = 0;
  for (var i = 0; i < string.length; i++) {
    var code = string.charCodeAt(i);
    hash = (hash << 5) - hash + code;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

export function unescapeHtml(input) {
  return input
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;>/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
    .replace(/&#39;/g, "'")
    .replace(/&#034;/g, '"')
    .replace(/&#34;/g, '"');
}
