import {
  Container,
  Box,
  chakra,
  Flex,
  Icon,
  Text,
  Button,
  useToken,
  VStack,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import Link from "next/link";
import Navbar from "../Navbar";
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useDevice } from "@/contexts/DeviceContext";
import { useRouter } from "next/router";

export const SkewedObject = ({ bg, top = "-8em" }) => {
  return (
    <chakra.svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      transform="rotate(0deg)"
      transition="0.3s"
      pos="absolute"
      top={top}
      left={0}
      width="100%"
    >
      <path
        style={{ transform: "translate(0, 0px)", opacity: 1 }}
        fill={bg}
        d="M0,16L120,32C240,48,480,80,720,77.3C960,75,1200,37,1440,40C1680,43,1920,85,2160,101.3C2400,117,2640,107,2880,96C3120,85,3360,75,3600,61.3C3840,48,4080,32,4320,21.3C4560,11,4800,5,5040,5.3C5280,5,5520,11,5760,24C6000,37,6240,59,6480,58.7C6720,59,6960,37,7200,40C7440,43,7680,69,7920,88C8160,107,8400,117,8640,109.3C8880,101,9120,75,9360,58.7C9600,43,9840,37,10080,50.7C10320,64,10560,96,10800,114.7C11040,133,11280,139,11520,128C11760,117,12000,91,12240,88C12480,85,12720,107,12960,114.7C13200,123,13440,117,13680,109.3C13920,101,14160,91,14400,74.7C14640,59,14880,37,15120,45.3C15360,53,15600,91,15840,112C16080,133,16320,139,16560,122.7C16800,107,17040,69,17160,50.7L17280,32L17280,160L17160,160C17040,160,16800,160,16560,160C16320,160,16080,160,15840,160C15600,160,15360,160,15120,160C14880,160,14640,160,14400,160C14160,160,13920,160,13680,160C13440,160,13200,160,12960,160C12720,160,12480,160,12240,160C12000,160,11760,160,11520,160C11280,160,11040,160,10800,160C10560,160,10320,160,10080,160C9840,160,9600,160,9360,160C9120,160,8880,160,8640,160C8400,160,8160,160,7920,160C7680,160,7440,160,7200,160C6960,160,6720,160,6480,160C6240,160,6000,160,5760,160C5520,160,5280,160,5040,160C4800,160,4560,160,4320,160C4080,160,3840,160,3600,160C3360,160,3120,160,2880,160C2640,160,2400,160,2160,160C1920,160,1680,160,1440,160C1200,160,960,160,720,160C480,160,240,160,120,160L0,160Z"
      />
    </chakra.svg>
  );
};

export const SkewedContainer = ({ bg = "red", children, containerProps = {}, top, ...rest }) => {
  return (
    <Box pos="relative" mt={10} {...rest}>
      {!!bg && <SkewedObject bg={bg} top={top} />}

      <Container pt={5} {...containerProps}>
        {children}
      </Container>
    </Box>
  );
};

// deprecated
export const DeprecatedSkewedObject = ({ angle = 6, as, ...rest }) => {
  let Component = as;
  return (
    <Component
      pos="absolute"
      top={0}
      left={0}
      w="100%"
      h="100%"
      zIndex={-1}
      transform="auto"
      skewY={`${-angle}deg`}
      {...rest}
    />
  );
};

// deprecated
export const DeprecatedSkewedContainer = ({
  bg,
  angle = 6,
  transformOrigin = "top right",
  containerProps = {},
  children,
  ...rest
}) => {
  return (
    <Box pos="relative" mt={10} {...rest}>
      {!!bg && (
        <DeprecatedSkewedObject as={Box} bg={bg} angle={angle} transformOrigin={transformOrigin} />
      )}
      <Container {...containerProps}>{children}</Container>
    </Box>
  );
};

export const SkewedHeader = ({ bg, children }) => (
  <Flex pos="relative" direction="column">
    <Navbar inverted />
    <Box
      bg={bg}
      pos="absolute"
      w="100vw"
      h={{ base: 52, md: 72 }}
      zIndex={-1}
      transform="skewY(-4deg)"
      transformOrigin="top left"
    />
    <Container my={4}>
      <chakra.h1 as="h1" textStyle="pageHeader" color="white">
        {children}
      </chakra.h1>
    </Container>
  </Flex>
);

export const IconBox = ({ icon, label, color, onClick }) => {
  const resolvedColor = useToken("colors", color);
  return (
    <VStack
      p={6}
      mt={6}
      shadow={`-0.3rem 0.3rem ${resolvedColor}`}
      border="1px solid #00000088"
      borderRadius="xl"
      cursor="pointer"
      onClick={onClick}
    >
      <Icon as={icon} boxSize={20} />
      <br />
      <Text fontSize="2xl" fontWeight="bold">
        {label}
      </Text>
    </VStack>
  );
};

export const EmojiBox = ({ emoji, label, color, onClick }) => {
  const resolvedColor = useToken("colors", color);
  return (
    <VStack
      p={6}
      mt={6}
      shadow={`-0.3rem 0.3rem ${resolvedColor}`}
      border="1px solid #00000088"
      borderRadius="xl"
      cursor="pointer"
      onClick={onClick}
    >
      <Text fontSize="6xl" lineHeight={{ base: 0.9, md: 1.15 }}>
        {emoji}
      </Text>
      <Text fontSize="2xl" lineHeight={0.9} fontFamily="heading">
        {label}
      </Text>
    </VStack>
  );
};

export const GradientText = ({
  from,
  to,
  icon,
  children,
  degree = "90deg",
  mobileBg = "white",
  ...rest
}) => {
  const { isMobile } = useDevice();

  if (isMobile) {
    return (
      <Text fontSize="2xl" fontWeight="bold" bg={mobileBg} bgClip="text" {...rest}>
        {icon && <Icon as={icon} color={mobileBg} mr={2} />}

        {children}
      </Text>
    );
  } else {
    return (
      <Text
        fontSize="2xl"
        fontWeight="bold"
        bg={`linear-gradient(${degree}, ${from}, ${to})`}
        bgClip="text"
        {...rest}
      >
        {icon && <Icon as={icon} color={from} mr={2} />}

        {children}
      </Text>
    );
  }
};

export const CheckedItemsList = ({ items, colorScheme = "green", ...rest }) => (
  <List {...rest}>
    {items.map((item, i) => (
      <ListItem key={i}>
        <ListIcon
          translateY="-0.15rem"
          transform="auto"
          as={FaCheckCircle}
          color={`${colorScheme}.500`}
        />
        {item}
      </ListItem>
    ))}
  </List>
);

export const LinkButton = ({
  href,
  target = "_self",
  onClick,
  prefetch = true,
  children,
  ...rest
}) => {
  const isExternal = href.startsWith("http") || target === "_blank";

  const { isMobile } = useDevice();
  const { pathname } = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const onButtonClick = (e) => {
    setIsLoading(true);

    if (!!onClick) {
      onClick(e);
    }

    if (isExternal) {
      setIsLoading(false);
    }
  };

  if (isExternal) {
    return (
      <a href={href} target={target}>
        <Button
          isLoading={isLoading}
          onClick={onButtonClick}
          {...rest}
          isDisabled={pathname === href}
        >
          {children}
        </Button>
      </a>
    );
  }

  return (
    <Link href={href} prefetch={prefetch && !isMobile} legacyBehavior>
      <Button
        isLoading={isLoading}
        onClick={onButtonClick}
        {...rest}
        isDisabled={pathname === href}
      >
        {children}
      </Button>
    </Link>
  );
};

export const NullableProperty = ({ label, value, nullValue, defaultValue, ...rest }) => {
  let valueAsString;
  if (!value || value == nullValue) {
    if (defaultValue) {
      valueAsString = defaultValue;
    } else {
      return;
    }
  } else if (Array.isArray(value)) {
    valueAsString = value.length === 0 ? defaultValue : value.join(", ");
  } else {
    valueAsString = value;
  }

  return (
    <Text {...rest}>
      <b>{label}:</b> {valueAsString}
    </Text>
  );
};
